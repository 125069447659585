<template>
  <v-hover v-slot="{ hover }" style="background-color: transparent">
    <v-sheet outlined rounded="xl" :class="{ 'primary--border': hover }" :v-bind="$attrs">
      <slot />
    </v-sheet>
  </v-hover>
</template>

<script>
export default {}
</script>

<style></style>
